import logo from './logo.svg';
import './App.css';
import {Home} from "./pages/Home";
import {CssBaseline, ThemeProvider} from "@mui/material";
import {bperTheme} from "./theme/bperTheme";

function App() {
  return (
      <ThemeProvider theme={bperTheme}>
          <CssBaseline/>
          <div className="App">
              <Home/>
          </div>
      </ThemeProvider>
  );
}

export default App;
