import React, {useEffect, useState} from "react";
import {
    Backdrop,
    Box,
    Button,
    Card, Checkbox, CircularProgress, Collapse,
    Container, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, Icon, IconButton, Slide, Snackbar,
    Stack,
    TextField,
    Typography,
    useMediaQuery,
    useTheme
} from "@mui/material";
import logo from '../assets/logo.png'
import claim from '../assets/claim.png'
import axios from "axios";
import MuiAlert from '@mui/material/Alert';
//import qr from '../assets/BPER_neutro.png'
import {ContentCopy, CopyAll, Telegram, WhatsApp} from "@mui/icons-material";

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export const Home = () => {
    const theme = useTheme()
    const matches = useMediaQuery(theme.breakpoints.up('sm'));
    const [fields, setFields] = useState([])
    const [loading, setLoading] = useState(true)
    const [values, setValues] = useState({
        domanda: ''
    })
    const [sending, setSending] = useState(false)
    const [error, setError] = useState(false)
    const [success, setSuccess] = useState(false)
    const [open, setOpen] = useState(false)
    const [anonimo, setAnonimo] = useState(false)
    const [share, setShare] = useState(false)
    const [copiato, setCopiato] = useState(false)

    const link = 'https://weareone.2ndstage.app'

    useEffect(() => {
        axios.get('https://api-tool-press-yn26wrnl4q-ey.a.run.app/TbclhS1bu7oFTTzoF8ne/columns')
            .then(response => {
                const obj = {}
                setFields(response.data)
                response.data.map(r => obj[r] = '')
                setValues({...values, ...obj})
                setLoading(false)
            })
    }, [])

    const handleChangeValue = (e, key) => {
        setValues({...values, [key]: e.target.value})
    }

    const handleSetSuccess = (bool) => {
        setSuccess(bool)
    }

    const handleSetError = (bool) => {
        setSuccess(bool)
    }

    const sendQuestion = (e) => {
        e.preventDefault()
        setSending(true)
        postQuestions()
            .then(() => {
                setSuccess(true)
                setOpen(true)
                setTimeout(()=> setOpen(false),5000)
            })
            .catch(() => setError(true))
        setSending(false)
    }

    const postQuestions = async () => {
        let val = values
        if(anonimo){
            val = {...values, Nome:'Anonimo', Cognome:'Anonimo'}
        }
        await axios.post('https://api-tool-press-yn26wrnl4q-ey.a.run.app/TbclhS1bu7oFTTzoF8ne/question', val)
        setValues(prevState =>( {...prevState, domanda: ''}))
    }

    const handleSetAnonimo = (e) => {
        setAnonimo(e.target.checked)
    }

    return (
        <Stack className={'home'} direction={"column"} alignItems={"center"}
               justifyContent={"space-between"} sx={{pt: 3, height: '93vh', maxHeight: '-webkit-fill-available', background:'#005157'}}>
            <img src={logo} style={{width: '100%', maxWidth: '7rem', paddingBottom:'0rem'}}/>
            <Snackbar open={copiato} autoHideDuration={4000} onClose={() => setCopiato(false)}>
                <Alert onClose={() => setCopiato(false)} severity="success" sx={{width: '100%'}}>
                    Copiato negli appunti
                </Alert>
            </Snackbar>
            <Snackbar open={error} autoHideDuration={6000} onClose={() => handleSetError(false)}>
                <Alert onClose={() => handleSetError(false)} severity="error" sx={{width: '100%'}}>
                    C'è stato un errore, si prega di riprovare
                </Alert>
            </Snackbar>
            <Backdrop
                sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}
                open={sending || loading}
            >
                <CircularProgress color="inherit"/>
            </Backdrop>
            <Dialog open={share} TransitionComponent={Transition} onClose={() => setShare(false)}>
                {/*<img src={qr} style={{width: '100%'}}/>*/}
                <Stack direction={"column"} alignItems={"center"} sx={{p:2}}>
                    Condividi:
                    <Box sx={{display:'flex', flexDirection:'row', my:1}}>
                        <IconButton sx={{border:'1px solid'}} href={`whatsapp://send?text= Invia la tua domanda qui: ${link}`} target={'_blank'}>
                            <WhatsApp/>
                        </IconButton>
                        <IconButton sx={{border:'1px solid', mx:2}} href={`tg://msg?text=Invia la tua domanda qui: ${link}`}>
                            <Telegram/>
                        </IconButton>
                        <IconButton sx={{border:'1px solid'}} onClick={() => {navigator.clipboard.writeText(link).then(()=> setCopiato(true))}}>
                            <ContentCopy/>
                        </IconButton>
                    </Box>
                </Stack>
                <DialogActions sx={{justifyContent:'center', pb:2}}>
                    <Button variant={"contained"} onClick={()=> setShare(false)}>
                        Chiudi
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={open}
                TransitionComponent={Transition}
                onClose={()=> setOpen(false)}
            >
                <DialogTitle>
                    <Typography variant={'h6'} textAlign={'center'} color={'#0096ad'}>
                        Domanda inviata con successo
                    </Typography>
                </DialogTitle>
                <DialogActions sx={{justifyContent:'center', pb:2}}>
                    <Button variant={"contained"} onClick={()=> setOpen(false)}>
                        Chiudi
                    </Button>
                </DialogActions>
            </Dialog>
            {(!loading) &&
                <Container maxWidth={'sm'}>
                    <Card variant={'domanda'} sx={{p: 4, py:0, textAlign: 'left'}}>
                        <form style={{padding: '1rem 0'}} onSubmit={sendQuestion}>
                            <Typography variant={'h5'} fontWeight={'bold'} textAlign={'center'} color={'#005157'}>Scrivi qui la tua domanda</Typography>
                            <Stack direction={"column"} spacing={1} sx={{py:1}}>
                                <FormControlLabel control={<Checkbox checked={anonimo} onChange={handleSetAnonimo}/>}
                                                   label="Domanda anonima"/>
                                <Stack spacing={1} sx={{pb:2}}>
                                    {!anonimo && fields.map(f =>
                                        <Box className={'field-1'}>
                                            <TextField disabled={anonimo} sx={{width: '100%'}} variant={"standard"}
                                                       label={f}
                                                       required={!anonimo}
                                                       value={values[f]} onChange={(e) => handleChangeValue(e, f)}
                                            />
                                        </Box>
                                    )}
                                    <Box className={'field-2'} sx={{pt:1}}>
                                        <TextField required value={values['domanda']}
                                                   onChange={(e) => handleChangeValue(e, 'domanda')} sx={{width: '100%'}}
                                                   label={'Domanda'} multiline minRows={2}/>
                                    </Box>
                                </Stack>
                                <Box style={{width: '100%', textAlign: 'center'}}>
                                    <Button className={'send-button'} variant={"contained"}
                                            type={"submit"}
                                            sx={{borderRadius: 0, width: '14rem', alignSelf: 'center'}}>
                                        {success ? "Invia un'altra domanda" : 'Invia domanda'}
                                    </Button>
                                    <Typography textAlign={"center"} onClick={() => setShare(true)} size={"small"}
                                                sx={{mt: 2, textDecoration: 'underline', color: '#07ad65'}}>
                                        Condividi questa pagina
                                    </Typography>
                                </Box>
                            </Stack>
                        </form>
                    </Card>
                </Container>}
            <Box sx={{width:'100%', textAlign:'center'}}>
                <img style={{width: 'min(100%, 14rem)', margin:'auto', paddingBottom:'1rem'}} src={claim}/>
            </Box>
        </Stack>
    )
}
