import {createTheme} from "@mui/material";

export const bperTheme = createTheme({
    components: {
        MuiCard: {
            variants:[
                {
                    props:{variant:'domanda'},
                    style:{
                        background: '#fff',
                        color:'#151515',
                        borderRadius:0
                    }
                }
            ]
        }
    },
    palette:{
        mode:'light',
        primary:{
            main: '#07ad65',
            contrastText:'white'
        },
        background:{
            default:'#B82B4A'
        }
    }
})
